
import { defineComponent } from "vue";
import { VueBottomSheet } from "@webzlodimir/vue-bottom-sheet";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import DefaultButton from "./common/DefaultButton.vue";

interface Option {
  display: string;
  value: string | number;
}

type Options = Array<Option>;

export default defineComponent({
  name: "BottomSheet",
  components: { VueBottomSheet, DefaultButton },
  emits: ["onClick", "update:modelValue", "select"],
  props: {
    bottomTitle: {
      type: String,
    },
    options: {
      type: Object as () => Options,
    },
    spanText: {
      type: String,
    },
    labelText: {
      type: String,
    },
    btnText: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue(value) {
      const sheet = this.$refs.myBottomSheet as typeof VueBottomSheet;

      if (value) {
        this.$flutter.callHandler("hideGnb");
        setTimeout(() => {
          sheet.open();
        }, 200);
      } else {
        sheet.close();
        setTimeout(() => {
          this.$flutter.callHandler("showGnb");
        }, 200);
      }
    },
  },
  methods: {
    closed() {
      this.$emit("update:modelValue", false);
    },
    select(option: Option) {
      this.$emit("select", option);
      this.$emit("update:modelValue", false);
    },
  },
});
