import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c08ec92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom-sheet" }
const _hoisted_2 = {
  key: 0,
  class: "bottom-option-wrap"
}
const _hoisted_3 = { class: "bottom-title" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "span-text" }
const _hoisted_7 = { class: "span-text-with-label" }
const _hoisted_8 = { class: "label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultButton = _resolveComponent("DefaultButton")!
  const _component_VueBottomSheet = _resolveComponent("VueBottomSheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VueBottomSheet, {
      ref: "myBottomSheet",
      "background-scrollable": false,
      onClosed: _ctx.closed
    }, {
      default: _withCtx(() => [
        (_ctx.options)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.bottomTitle), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'vuebottom' + option.value,
                  class: "bottom-sub",
                  onClick: ($event: any) => (_ctx.select(option))
                }, _toDisplayString(option.display), 9, _hoisted_4))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (!_ctx.labelText)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.spanText), 1),
                    _createVNode(_component_DefaultButton, { marginBottom: "50px" })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.spanText), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.labelText), 1),
                    _createVNode(_component_DefaultButton, {
                      class: "btn-bs",
                      marginBottom: "50px",
                      disabled: false,
                      backgroundColor: 
              _ctx.$isDarkMode() ? 'var(--black1-color)' : 'var(--white-color)'
            ,
                      color: _ctx.$isDarkMode() ? 'var(--white-color)' : 'var(--blue1-color)',
                      borderColor: "var(--blue1-color)",
                      borderWidth: "1px",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.btnText), 1)
                      ]),
                      _: 1
                    }, 8, ["backgroundColor", "color"])
                  ], 64))
            ]))
      ]),
      _: 1
    }, 8, ["onClosed"])
  ]))
}